import React, { useState } from "react";
import "./AgeConfirmationModal.scss";
import { theme, Modal, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import LocalStorageService from "../../common/services/LocalStorageService";

export default function AgeConfirmationModal() {
  const { useToken } = theme;
  const { token } = useToken();
  const is_user_over_21 = LocalStorageService?.get("is_user_over_21")
    ? true
    : false;
  const [isAgeModalOpened, setIsAgeModalOpened] = useState(
    is_user_over_21 ? false : true
  );

  const verifyAge = () => {
    LocalStorageService?.set("is_user_over_21", true);
    setIsAgeModalOpened(false);
  };

  return (
    <Modal
      centered
      closeIcon={false}
      styles={{
        mask: {
          boxShadow: `inset 0 0 20px rgba(255, 255, 255, 0.6)`,
          backdropFilter: "blur(20px)",
        },
        content: {
          border: `1px solid ${token?.colorPrimary}`,
        },
        footer: {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          width: "100%",
          marginLeft: "40px",
        },
      }}
      className="ageModal-cont"
      onOk={() => {
        verifyAge();
      }}
      okText="Confirm"
      cancelText="Cancel"
      onCancel={() => {
        window.history.back();
        // setIsAgeModalOpened(false);
      }}
      maskClosable={false}
      title={
        <div className="ageModal-cont__titleWrapper">
          <ExclamationCircleOutlined
            style={{
              color: `${token?.colorWarning}`,
            }}
            className="ageModal-cont__titleWrapper__icon"
          />
          <Typography.Text className="ageModal-cont__titleWrapper__title">
            Please verify your age !
          </Typography.Text>
        </div>
      }
      cancelButtonProps={{
        className: "ageModal-cont__btn",
      }}
      okButtonProps={{
        type: "primary",
        className: "ageModal-cont__btn",
      }}
      open={isAgeModalOpened}>
      <Typography.Text className="ageModal-cont__subTitle">
        You must be 21 or over 18 with medical card to view and use this
        website.
      </Typography.Text>
    </Modal>
  );
}
