import React, { useEffect, useRef, useState, useCallback } from "react";
import "./Products.scss";
import {
  theme,
  Typography,
  Input,
  Select,
  Button,
  Dropdown,
  Pagination,
  Spin,
  notification,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { CustomEmpty, ProductCard, ProductDrawer } from "../../components";
import {
  SearchOutlined,
  FunnelPlotOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { invertTheme } from "../../config/theme";
import { ProductFilters, PromoCodesSlider } from "./components";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaxPriceMenuItem,
  getMenuItems,
} from "../../features/menuItems/menuItemsAction";
import { getCategories } from "../../features/categories/categoriesActions";
import { getBrands } from "../../features/brands/brandsActions";
import classNames from "classnames";
import Utils from "../../utils";
import { resetMenuItemsError } from "../../features/menuItems/menuItemsSlice";

export default function Products() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { width, isMobile } = useWindowWidth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const {
    loading,
    error,
    products: { meta, results: products },
  } = useSelector((state) => state?.menuItems);
  const {
    loading: catgeoriesLoading,
    categories: { results: categories },
  } = useSelector((state) => state?.categories);
  const {
    loading: brandsLoading,
    brands: { results: brands },
  } = useSelector((state) => state?.brands);

  const formRef = useRef(null);
  const productContRef = useRef(null);
  const [searchVal, setSearchVal] = useState("");
  const [orderBy, setOrderBy] = useState(undefined);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(undefined);
  const [selectedGenetics, setSelectedGenetics] = useState([]);
  const [isOnsale, setIsOnSale] = useState(false);
  const [priceRange, setPriceRange] = useState([]);
  const [arrangeOrder, setArrangeOrder] = useState("");
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const arrangeOptions = [
    {
      key: "ascending",
      label: "Ascending",
    },
    {
      key: "descending",
      label: "Descending",
    },
    {
      key: "reset",
      label: "Reset",
    },
  ];

  const getProductsByFilter = (params) => {
    dispatch(
      getMenuItems({
        page: page ? page : undefined,
        per_page: perPage ? perPage : undefined,
        name: searchVal ? searchVal : undefined,
        category: selectedCategories?.length ? selectedCategories : undefined,
        brand: selectedBrand?.label ? selectedBrand?.label : undefined,
        genetics: selectedGenetics?.length ? selectedGenetics : undefined,
        price_gte:
          priceRange[0] >= 0 && priceRange[0] <= priceRange[1]
            ? priceRange[0]
            : undefined,
        price_lte:
          priceRange[1] >= 0 && priceRange[1] >= priceRange[0]
            ? priceRange[1]
            : undefined,
        order_by: orderBy ? orderBy : undefined,
        sale: isOnsale ? isOnsale : undefined,
        count_gt: 0,
        ...params,
      })
    );
  };

  const onSearchInpChange = Utils?.debounce((e) => {
    setSearchVal(e.target.value?.toLowerCase());
    setPage(1);
    setPerPage(25);
    getProductsByFilter({
      page: 1,
      per_page: 25,
      name: e.target.value?.toLowerCase()
        ? e.target.value?.toLowerCase()
        : undefined,
    });
  }, 500);

  const onOrderChange = (val) => {
    setPage(1);
    setPerPage(25);
    if (val === "ascending") {
      setOrderBy("price");
      getProductsByFilter({
        page: 1,
        per_page: 25,
        order_by: "price",
      });
    } else if (val === "descending") {
      setOrderBy("-price");
      getProductsByFilter({
        page: 1,
        per_page: 25,
        order_by: "-price",
      });
    }
  };

  const onOrderClear = () => {
    setPage(1);
    setPerPage(25);
    setOrderBy(undefined);
    getProductsByFilter({
      page: 1,
      per_page: 25,
      order_by: undefined,
    });
  };

  useEffect(() => {
    dispatch(
      getMaxPriceMenuItem({
        order_by: "-price",
        count_gt: 0,
      })
    )?.then((res) => {
      if (res?.type === "get_maxPrice_menu_item/fulfilled") {
        const dataByDescOrder = res?.payload;
        const items = dataByDescOrder?.results;
        const maxPrice = Utils?.getMaxPrice(items);
        setMaxPrice(maxPrice ? maxPrice : 1000);
        setPriceRange([0, maxPrice ? maxPrice : 1000]);
        dispatch(
          getMenuItems({
            page: 1,
            per_page: 25,
            price_gte: 0,
            price_lte: maxPrice ? maxPrice : 1000,
            count_gt: 0,
          })
        );
      }
    });
    dispatch(
      getCategories({ page: 1, per_page: 100000, parent__isnull: true })
    );
    dispatch(getBrands({ page: 1, per_page: 100000 }));
  }, [dispatch, isAuthenticated]);

  const handlePagination = (e, a) => {
    setPage(e);
    setPerPage(a);
    getProductsByFilter({ page: e, per_page: a });
  };

  const onCategoryChange = (categories) => {
    setPage(1);
    setPerPage(25);
    setSelectedCategories(categories?.length ? categories : []);
    getProductsByFilter({
      page: 1,
      per_page: 25,
      category: categories?.length ? categories : undefined,
    });
  };

  const onBrandChange = (_, brand) => {
    setPage(1);
    setPerPage(25);
    setSelectedBrand(brand ? brand : undefined);
    getProductsByFilter({
      page: 1,
      per_page: 25,
      brand: brand?.label ? brand?.label : undefined,
    });
  };

  const onGeneticsChange = (genetics) => {
    setPage(1);
    setPerPage(25);
    setSelectedGenetics(genetics?.length ? genetics : undefined);
    getProductsByFilter({
      page: 1,
      per_page: 25,
      genetics: genetics?.length ? genetics : undefined,
    });
  };

  const onCheckboxChange = (e) => {
    setPage(1);
    setPerPage(25);
    setIsOnSale(e.target.checked);
    getProductsByFilter({
      page: 1,
      per_page: 25,
      sale: e.target.checked ? e.target.checked : undefined,
    });
  };

  const onPriceRangeChange = Utils?.debounce((range) => {
    setPage(1);
    setPerPage(25);
    setPriceRange(range);
    getProductsByFilter({
      page: 1,
      per_page: 25,
      price_gte: range[0] >= 0 && range[0] <= range[1] ? range[0] : undefined,
      price_lte: range[1] >= 0 || range[1] >= range[0] ? range[1] : undefined,
    });
  }, 750);

  const openDrawer = (product) => {
    setIsDrawerOpened(true);
    setSelectedProduct(product);
  };

  useEffect(() => {
    if (location?.pathname === "/") {
      if (error && !loading) {
        for (let key in error) {
          openNotificationWithIcon("error", key, error[key]);
        }
        dispatch(resetMenuItemsError());
      }
    }
  }, [error, loading, location?.pathname, openNotificationWithIcon, dispatch]);

  return (
    <>
      {contextHolder}
      <Content
        className="products-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <PromoCodesSlider isAuthenticated={isAuthenticated} token={token} />
        <div className="products-cont__globalFilters">
          {isMobile ? (
            <div className="products-cont__globalFilters__productsCountWrapper">
              <div className="products-cont__globalFilters__productsCountWrapper__countCont">
                <div className="products-cont__globalFilters__productsCountWrapper__textsWrapper">
                  <Typography.Text
                    className="products-cont__globalFilters__productsCountWrapper__text"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    All Products
                  </Typography.Text>
                  <Typography.Text
                    className="products-cont__globalFilters__productsCountWrapper__text"
                    style={{
                      color: token?.Typography?.colorPrimaryText,
                    }}>
                    {meta?.count ? meta?.count : 0}
                  </Typography.Text>
                  <Typography.Text
                    className="products-cont__globalFilters__productsCountWrapper__text"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}>
                    results
                  </Typography.Text>
                </div>
                <Dropdown
                  className="products-cont__globalFilters__productsCountWrapper__countCont__dropDown"
                  trigger={["click"]}
                  disabled={loading}
                  menu={{
                    items: arrangeOptions,
                    onClick: ({ key }) => {
                      const foundArrangeOption = arrangeOptions?.find(
                        (el) => el?.key === key
                      );
                      setArrangeOrder(foundArrangeOption?.label);
                      setPage(1);
                      setPerPage(25);
                      if (key === "ascending") {
                        setOrderBy("price");
                        getProductsByFilter({
                          page: 1,
                          per_page: 25,
                          order_by: "price",
                        });
                      } else if (key === "descending") {
                        setOrderBy("-price");
                        getProductsByFilter({
                          page: 1,
                          per_page: 25,
                          order_by: "-price",
                        });
                      } else {
                        setOrderBy(undefined);
                        getProductsByFilter({
                          page: 1,
                          per_page: 25,
                          order_by: undefined,
                        });
                      }
                    },
                  }}>
                  <div className="products-cont__globalFilters__productsCountWrapper__countCont__dropDown__content">
                    <Typography.Text
                      className="products-cont__globalFilters__productsCountWrapper__countCont__dropDown__content__text"
                      style={{
                        color: token?.Typography?.mainColorText,
                      }}>
                      {arrangeOrder && arrangeOrder !== "Reset"
                        ? arrangeOrder
                        : "Sort By"}
                    </Typography.Text>
                    <DownOutlined
                      style={{
                        fontSize: "14px",
                        color: token?.icons?.whiteIcon,
                      }}
                    />
                  </div>
                </Dropdown>
              </div>
              <Button
                type={isFilterOpened ? "primary" : ""}
                icon={
                  <FunnelPlotOutlined
                    style={{
                      color: isFilterOpened
                        ? token?.icons?.bgColorIcon
                        : token?.icons?.whiteIcon,
                      fontSize: "16px",
                    }}
                  />
                }
                size="small"
                onClick={() => {
                  setIsFilterOpened(!isFilterOpened);
                }}
              />
            </div>
          ) : (
            <div className="products-cont__globalFilters__productsCountWrapper">
              <Typography.Text
                className="products-cont__globalFilters__productsCountWrapper__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                All Products
              </Typography.Text>
              <div className="products-cont__globalFilters__productsCountWrapper__textsWrapper">
                <Typography.Text
                  className="products-cont__globalFilters__productsCountWrapper__text"
                  style={{
                    color: token?.Typography?.colorPrimaryText,
                  }}>
                  {meta?.count ? meta?.count : 0}
                </Typography.Text>
                <Typography.Text
                  className="products-cont__globalFilters__productsCountWrapper__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  results
                </Typography.Text>
              </div>
            </div>
          )}
          <div className="products-cont__globalFilters__filtersWrapper">
            {isMobile && !isFilterOpened ? null : (
              <Input
                className="products-cont__globalFilters__filtersWrapper__searchInp"
                size="large"
                placeholder="Brands, products, retailers and more"
                prefix={<SearchOutlined />}
                allowClear
                onChange={onSearchInpChange}
              />
            )}
            {isMobile ? null : (
              <div className="products-cont__globalFilters__filtersWrapper__selectWrapper">
                <Typography.Text
                  className="products-cont__globalFilters__filtersWrapper__selectWrapper__text"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  Sort By :
                </Typography.Text>

                <Select
                  className="products-cont__globalFilters__filtersWrapper__selectWrapper__select"
                  placeholder="Sort by"
                  size="large"
                  options={[
                    { label: "Ascending", value: "ascending" },
                    { label: "Descending", value: "descending" },
                  ]}
                  allowClear
                  onChange={onOrderChange}
                  disabled={loading}
                  onClear={onOrderClear}
                />
              </div>
            )}
          </div>
        </div>
        <div className="products-cont__products">
          <ProductFilters
            token={token}
            formRef={formRef}
            invertTheme={invertTheme}
            isMobile={isMobile}
            isFilterOpened={isFilterOpened}
            loading={loading}
            categories={categories}
            catgeoriesLoading={catgeoriesLoading}
            selectedCategories={selectedCategories}
            onCategoryChange={onCategoryChange}
            filterOption={Utils?.filterOption}
            brands={brands}
            brandsLoading={brandsLoading}
            selectedBrand={selectedBrand}
            onBrandChange={onBrandChange}
            selectedGenetics={selectedGenetics}
            onGeneticsChange={onGeneticsChange}
            priceRange={priceRange}
            onPriceRangeChange={onPriceRangeChange}
            isOnsale={isOnsale}
            onCheckboxChange={onCheckboxChange}
            maxPrice={maxPrice}
          />
          {isMobile ? (
            <div className="products-cont__products__titleWrapper">
              <Typography.Text
                className="products-cont__products__titleWrapper__title"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                All The Products
              </Typography.Text>
            </div>
          ) : null}
          <div
            ref={productContRef}
            style={{
              gridTemplateColumns:
                !loading && products?.length
                  ? `repeat(auto-fit, minmax(${
                      isMobile ? "168px" : "179.3px"
                    }, ${
                      isMobile
                        ? 350
                        : 250 / productContRef?.current?.offsetWidth
                    }fr))`
                  : `repeat(auto-fit, minmax(${
                      isMobile ? "168px" : "179.3px"
                    },1fr))`,
            }}
            className={classNames("products-cont__products__cartsCont", {
              "products-cont__spinWrapper": loading || !products?.length,
            })}>
            {!products?.length && !loading ? (
              <CustomEmpty
                circleColor={token?.colorText ? token?.colorText : ""}
                boxBorderColor={token?.colorText ? token?.colorText : ""}
              />
            ) : !loading ? (
              products?.map((product) => (
                <ProductCard
                  product={product}
                  key={product?.id}
                  navigate={() => {
                    navigate(`product/${product?.id}`, { state: product });
                  }}
                  haveDrawer={true}
                  openDrawer={openDrawer}
                />
              ))
            ) : (
              <Spin size="large" />
            )}
          </div>
        </div>
        <Pagination
          className="products-cont__pagination"
          disabled={loading}
          pageSize={perPage}
          current={page}
          defaultCurrent={1}
          total={meta?.count ? meta?.count : ""}
          responsive={true}
          onChange={handlePagination}
          showQuickJumper={width < 584 && width >= 577 ? false : true}
          showSizeChanger={false}
        />
        <ProductDrawer
          isDrawerOpened={isDrawerOpened}
          close={() => {
            setIsDrawerOpened(false);
            setSelectedProduct(null);
          }}
          product={selectedProduct}
        />
      </Content>
    </>
  );
}
