import React from "react";
import "./ProductFilters.scss";
import { Form, Typography, Select, Slider, Checkbox } from "antd";

export default function ProductFilters({
  token,
  formRef,
  isMobile,
  isFilterOpened,
  loading,
  categories,
  catgeoriesLoading,
  selectedCategories,
  onCategoryChange,
  filterOption,
  brands,
  brandsLoading,
  selectedBrand,
  onBrandChange,
  selectedGenetics,
  onGeneticsChange,
  priceRange,
  onPriceRangeChange,
  isOnsale,
  onCheckboxChange,
  maxPrice,
}) {
  const formatter = (value) => `$${value}`;

  return isMobile && !isFilterOpened ? null : (
    <div className="productFilters-cont">
      <Form
        className="productFilters-cont__form"
        layout="vertical"
        ref={formRef}>
        <Form.Item
          className="productFilters-cont__form__formItem"
          name="category"
          colon={false}
          label={
            <Typography.Text
              className="productFilters-cont__form__formItem__text"
              style={{
                color: token?.Typography?.mainColorText,
              }}>
              Categories
            </Typography.Text>
          }>
          <Select
            placeholder="Categories"
            allowClear
            showSearch
            size="large"
            disabled={loading || catgeoriesLoading}
            loading={catgeoriesLoading}
            value={selectedCategories}
            options={categories?.map((category) => ({
              label: category?.name ? category?.name : "",
              value: category?.name ? category?.name : "",
            }))}
            onChange={onCategoryChange}
            filterOption={filterOption}
            mode="multiple"
          />
        </Form.Item>
        <Form.Item
          className="productFilters-cont__form__formItem"
          name="brand"
          colon={false}
          label={
            <Typography.Text
              className="productFilters-cont__form__formItem__text"
              style={{
                color: token?.Typography?.mainColorText,
              }}>
              Brand
            </Typography.Text>
          }>
          <Select
            placeholder="Brand"
            allowClear
            showSearch
            size="large"
            disabled={loading || brandsLoading}
            loading={brandsLoading}
            value={selectedBrand}
            options={brands?.map((brand) => ({
              label: brand?.label ? brand?.label : "",
              value: brand?.id ? brand?.id : "",
            }))}
            onChange={onBrandChange}
            filterOption={filterOption}
          />
        </Form.Item>
        <Form.Item
          className="productFilters-cont__form__formItem"
          name="genetics"
          colon={false}
          label={
            <Typography.Text
              className="productFilters-cont__form__formItem__text"
              style={{
                color: token?.Typography?.mainColorText,
              }}>
              Genetics
            </Typography.Text>
          }>
          <Select
            placeholder="Genetics"
            allowClear
            showSearch
            size="large"
            disabled={loading}
            value={selectedGenetics}
            options={[
              {
                label: "Indica",
                value: "indica",
              },
              {
                label: "Sativa",
                value: "sativa",
              },
              {
                label: "Hybrid",
                value: "hybrid",
              },
            ]}
            onChange={onGeneticsChange}
            filterOption={filterOption}
            mode="multiple"
          />
        </Form.Item>
        {maxPrice && priceRange?.length ? (
          <Form.Item
            className="productFilters-cont__form__formItem"
            name="price_range"
            colon={false}
            label={
              <Typography.Text
                className="productFilters-cont__form__formItem__text"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                Price Range
              </Typography.Text>
            }>
            <div className="productFilters-cont__form__formItem__wrapper">
              <Slider
                style={{
                  width: "calc(100% - 14px)",
                }}
                range
                disabled={loading}
                tooltip={{
                  formatter,
                }}
                defaultValue={priceRange}
                min={0}
                max={maxPrice}
                onChange={onPriceRangeChange}
              />
            </div>
          </Form.Item>
        ) : null}
        <Form.Item className="productFilters-cont__form__formItem" name="sale">
          <Checkbox checked={isOnsale} onChange={onCheckboxChange}>
            <Typography.Text
              className="productFilters-cont__form__formItem__text"
              style={{
                color: token?.Typography?.mainColorText,
              }}>
              Sale
            </Typography.Text>
          </Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
}
